<template>
  <div class="scoreChartDetailContainer">
    <el-row>
      <el-button
        v-throttle
        @click="
          () => {
            this.$router.back();
          }
        "
        >返回</el-button
      >
      <el-button v-throttle type="primary" @click="handleDownloadChartData"
        >下载排行榜</el-button
      >
    </el-row>
    <el-row style="margin-top: 20px">
      <div class="tabsContainer" style="position: relative">
        <!-- 头部标题 -->
        <div
          class="tabsTitle"
          style="
            display: flex;
            font-size: 16px;
            color: gray;
            font-weight: 550;
            margin-bottom: 30px;
          "
        >
          <div
            :class="{ active: scoreRankType === 0 }"
            style="margin-right: 40px; cursor: pointer"
            @click="
              () => {
                scoreRankType = 0;
                changeBottomLineStyle(0);
              }
            "
          >
            个人排行
          </div>
          <div
            :class="{ active: scoreRankType === 1 }"
            style="cursor: pointer"
            @click="
              () => {
                scoreRankType = 1;
                changeBottomLineStyle(1);
              }
            "
          >
            学校排行
          </div>
          <div
            class="bottomLine"
            style="
              position: absolute;
              height: 2px;
              width: 64px;
              background-color: rgb(64, 158, 255);
              left: 0px;
              top: 30px;
              transition: 0.5s all;
            "
            :style="{ left: bottomLineLeftStyle }"
          ></div>
        </div>
        <!-- 表格和分页内容 -->
        <!-- 学生排行榜数据 -->
        <div v-show="scoreRankType === 0">
          <el-form :model="studentChartForm" class="studentChartFormContainer">
            <el-form-item style="width: 120px; margin-right: 10px">
              <el-select
                v-model="studentChartForm.sysOrgAreaId"
                @change="handleAreaSelectChange"
              >
                <el-option label="全部区域" :value="null"></el-option>
                <el-option
                  v-for="val in studentRankAreaList"
                  :key="val.sysOrgAreaId"
                  :label="val.sysOrgAreaName"
                  :value="val.sysOrgAreaId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="width: 180px; margin-right: 10px">
              <el-select
                v-model="studentChartForm.sysOrgSchoolId"
                @change="handleSchoolSelectChange"
              >
                <el-option label="全部学校" :value="null"></el-option>
                <el-option
                  v-for="val in studentRankSchoolList"
                  :key="val.sysOrgSchoolId"
                  :label="val.sysOrgSchoolName"
                  :value="val.sysOrgSchoolId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="width: 160px; margin-right: 10px">
              <el-select 
                v-model="studentChartForm.sysOrgSchoolClassId"
                @change="handleFindOutStudentRankBtn"
              >
                <el-option label="全部班级" :value="null"></el-option>
                <el-option
                  v-for="val in studentRankClassList"
                  :key="val.sysOrgSchoolClassId"
                  :label="val.sysOrgSchoolClassName"
                  :value="val.sysOrgSchoolClassId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="width: 100px; margin-right: 10px">
              <el-select 
                v-model="studentChartForm.subjectType"
                @change="handleFindOutStudentRankBtn"
              >
                <el-option
                  v-for="val in subjectTypeList"
                  :key="val"
                  :label="val | subjectTypeFilters"
                  :value="val"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item style="width: 180px; margin-right: 10px">
              <el-input
                v-model="studentChartForm.studentName"
                placeholder="学生名字搜索"
                @input="handleFindOutStudentRankBtn"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                @click="handleFindOutStudentRankBtn"
                v-throttle
                type="primary"
                style="margin-right: 10px"
                >查询</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                @click="handleResetStudentData"
                v-throttle
                style="
                  border-color: rgb(64, 158, 255);
                  color: rgb(64, 158, 255);
                "
                >重置</el-button
              >
            </el-form-item>
          </el-form>
          <el-table
            class="rankContainer"
            :data="studentChartTableData"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            @sort-change="handleSortChange"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column label="排名" prop="rank" sortable="custom">
              <template slot-scope="scoped">
                <div
                  :class="{
                    studentRank: scoped.row.ranking < 4,
                  }"
                >
                  {{ scoped.row.ranking }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="姓名" prop="studentName"></el-table-column>
            <el-table-column label="学号" prop="studentCode"></el-table-column>
            <el-table-column
              label="班级"
              prop="sysOrgSchoolClassName"
            ></el-table-column>
            <el-table-column
              label="区域"
              prop="sysOrgAreaName"
            ></el-table-column>
            <el-table-column
              label="学校"
              prop="sysOrgSchoolName"
            ></el-table-column>
            <el-table-column label="科目" prop="subjectType">
              <template slot-scope="scoped">
                <span>{{ scoped.row.subjectType | subjectTypeFilters }}</span>
              </template>
            </el-table-column>
            <el-table-column label="分数" prop="score"></el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <el-pagination
            @size-change="handleSizeChangeStudentChartMsgPage"
            @current-change="handleCurrentChangeStudentChartMsgPage"
            :current-page="studentChartMsgPage.pageIndex"
            :page-sizes="[10, 20, 50, 100, 150, 200]"
            :page-size="studentChartMsgPage.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="studentChartMsgPage.pageTotal"
            style="margin-top: 20px"
          ></el-pagination>
        </div>
        <!-- 学校排行榜数据 -->
        <div v-show="scoreRankType === 1">
          <el-form
            :inline="true"
            :model="schoolChartForm"
            class="studentChartFormContainer"
          >
            <el-form-item style="width: 120px; margin-right: 10px">
              <el-select
                v-model="schoolChartForm.sysOrgAreaId"
                @change="handleSchoolFormAreaSelectChange"
              >
                <el-option label="全部区域" :value="null"></el-option>
                <el-option
                  v-for="val in schoolFormAreaList"
                  :key="val.sysOrgAreaId"
                  :label="val.sysOrgAreaName"
                  :value="val.sysOrgAreaId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="width: 180px; margin-right: 10px">
              <el-select 
                v-model="schoolChartForm.sysOrgSchoolId"
                @change="handleFindOutSchoolRankDataBtn"
              >
                <el-option label="全部学校" :value="null"></el-option>
                <el-option
                  v-for="val in schoolFormSchoolList"
                  :key="val.sysOrgSchoolId"
                  :label="val.sysOrgSchoolName"
                  :value="val.sysOrgSchoolId"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- 考试学科 -->
            <el-form-item style="width: 100px; margin-right: 10px">
              <el-select 
                v-model="schoolChartForm.subjectType"
                @change="handleFindOutSchoolRankDataBtn"
              >
                <el-option
                  v-for="val in subjectTypeList"
                  :key="val"
                  :label="val | subjectTypeFilters"
                  :value="val"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button
                @click="handleFindOutSchoolRankDataBtn"
                v-throttle
                type="primary"
                style="margin-right: 10px"
                >查询</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                @click="handleResetSchoolData"
                v-throttle
                style="
                  border-color: rgb(64, 158, 255);
                  color: rgb(64, 158, 255);
                "
                >重置</el-button
              >
            </el-form-item>
          </el-form>
          <el-table
            class="rankContainer"
            :data="schoolChartTableData"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
            @sort-change="handleSortChange"
          >
            <el-table-column label="排名" prop="rank" sortable="custom">
              <template slot-scope="scoped">
                <div
                  :class="{
                    studentRank: scoped.row.ranking < 4,
                  }"
                >
                  {{ scoped.row.ranking }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="区域"
              prop="sysOrgAreaName"
            ></el-table-column>
            <el-table-column
              label="学校"
              prop="sysOrgSchoolName"
            ></el-table-column>
            <el-table-column
              label="考生数"
              prop="examineeCount"
            ></el-table-column>
            <el-table-column label="科目" prop="subjectType">
              <template slot-scope="scoped">
                {{ scoped.row.subjectType | subjectTypeFilters }}
              </template>
            </el-table-column>
            <el-table-column
              label="平均分"
              prop="averageScore"
            ></el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <el-pagination
            @size-change="handleSizeChangeSchoolChartMsgPage"
            @current-change="handleCurrentChangeSchoolChartMsgPage"
            :current-page="schoolChartMsgPage.pageIndex"
            :page-sizes="[10, 20, 50, 100, 150, 200]"
            :page-size="schoolChartMsgPage.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="schoolChartMsgPage.pageTotal"
            style="margin-top: 20px"
          ></el-pagination>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import {
  queryExamInfo,
  queryStudentRank,
  querySchoolRank,
  queryCondition,
  downloadPankDataApi,
} from "@/api/score/scoreQuery";
import { imageAddress } from "@/utils/publicFunc";
export default {
  name: "scoreChartDetail",
  data() {
    return {
      studentScoreList: [],
      schoolScoreList: [],
      scoreRankType: 0, // 0表示学生，1表示学校
      studentChartForm: {
        studentName: "",
        sysOrgSchoolClassId: null,
        subjectType: null,
        sysOrgSchoolId: null,
        sysOrgAreaId: null,
      },
      schoolChartForm: {
        sysOrgSchoolId: null,
        sysOrgAreaId: null,
        subjectType: "",
      },
      subjectTypeList: [],
      studentRankAreaList: [],
      studentRankSchoolList: [],
      studentRankClassList: [],
      examId: "",
      studentChartTableData: [],
      schoolChartTableData: [],
      testSchoolChartTableData: [],
      studentChartMsgPage: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      schoolChartMsgPage: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      schoolFormSchoolList: [],
      schoolFormAreaList: [],
      tempStudentRankCondition: [],
      sortType: 2, // 2表示顺序,1表示倒序
    };
  },
  computed: {
    bottomLineLeftStyle() {
      return this.scoreRankType === 0 ? "0px" : "104px";
    },
  },
  created() {
    let queryInfo = this.$route.query;
    this.studentChartForm.subjectType = +queryInfo.subject;
    this.schoolChartForm.subjectType = +queryInfo.subject;
    this.examId = queryInfo.examId;
    // 获取考试信息，设置学科数组
    queryExamInfo(queryInfo.examId).then((res) => {
      if (res.success) {
        this.subjectTypeList = res.data.subjectTypeList;
      } else {
        this.$message.error(res.msg);
      }
    });

    this.fetchStudentRankData();
    this.fetchSchoolRankData();
    this.getExamFilterConditionMsg();
  },
  methods: {
    fetchStudentRankData(data = this.studentChartForm) {
      // 获取学生排行榜数据
      let studentParams = {
        rankingType: 1,
        sortType: this.sortType,
        ...data,
      };
      for (const key in studentParams) {
       if(studentParams[key] == null || studentParams[key] == ""){
        delete studentParams[key]
       }
      }
      console.log(studentParams)
      queryStudentRank(
        this.examId,
        this.studentChartMsgPage.pageIndex,
        this.studentChartMsgPage.pageSize,
        studentParams
      ).then((res) => {
        if (res.success) {
          this.studentChartTableData = res.data;
          this.studentChartMsgPage.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    fetchSchoolRankData(data = this.schoolChartForm) {
      // 获取学校排行榜数据
      let schoolParams = {
        rankingType: 2,
        sortType: this.sortType,
        ...data,
      };
      this.testSchoolChartTableData = [];
      this.schoolChartTableData = [];
      for (const key in schoolParams) {
       if(schoolParams[key] == null || schoolParams[key] == ""){
        delete schoolParams[key]
       }
      }
      querySchoolRank(
        this.examId,
        this.schoolChartMsgPage.pageIndex,
        this.schoolChartMsgPage.pageSize,
        schoolParams
      ).then((res) => {
        if (res.success) {
          this.schoolChartTableData = res.data;
          this.schoolChartMsgPage.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 下载排行榜数据，这里调用接口,需判断一下是否是考试或考生数据
    handleDownloadChartData() {
      if (this.scoreRankType === 0) {
        let queryParams = {
          rankingType: 1,
          ...this.studentChartForm,
        };
        downloadPankDataApi(this.examId, queryParams).then((res) => {
          if (res.success) {
            this.$message.success("下载成功");
            window.open(imageAddress(res.data));
          } else {
            this.$message.error(res.msg);
            return;
          }
        });
      } else {
        let queryParams = {
          rankingType: 2,
          ...this.schoolChartForm,
        };
        downloadPankDataApi(this.examId, queryParams).then((res) => {
          if (res.success) {
            this.$message.success("下载成功");
            window.open(imageAddress(res.data));
          } else {
            this.$message.error(res.msg);
            return;
          }
        });
      }
    },
    // 获取考试过滤条件信息
    getExamFilterConditionMsg() {
      queryCondition(this.examId).then((res) => {
        if (res.success) {
          const { data } = res;
          this.tempStudentRankCondition =
            data.examAchievementAreaConditionDTOS;
          data.examAchievementAreaConditionDTOS.forEach((item) => {
            this.studentRankAreaList.push({
              sysOrgAreaId: item.sysOrgAreaId,
              sysOrgAreaName: item.sysOrgAreaName,
            });
          });
          this.schoolFormAreaList = this.studentRankAreaList;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 地区下拉选择
    handleAreaSelectChange(val) {
      if (!val) {
        this.studentRankSchoolList = [];
        this.studentRankClassList = [];
        this.studentChartForm.studentName = "";
        this.studentChartForm.sysOrgSchoolId = null;
        this.studentChartForm.sysOrgSchoolClassId = null;
      } else {
        // 选择成功后，就填充学校下拉表单
        this.tempStudentRankCondition.forEach((item) => {
          if (item.sysOrgAreaId === val) {
            this.studentRankSchoolList =
              item.examAchievementSchoolConditionDTOList;
          }
        });
        this.studentChartForm.studentName = "";
        this.studentChartForm.sysOrgSchoolId = null;
        this.studentChartForm.sysOrgSchoolClassId = null;
      }
      this.fetchStudentRankData()
    },
    // 学生表格学校下拉选择事件
    handleSchoolSelectChange(val) {
      if (!val) {
        this.studentRankClassList = [];
        this.studentChartForm.studentName = "";
        this.studentChartForm.sysOrgSchoolClassId = null;
      } else {
        // 选择成功后，就填充班级下拉表单
        this.tempStudentRankCondition.forEach((item1) => {
          item1.examAchievementSchoolConditionDTOList.forEach(
            (item2) => {
              if (item2.sysOrgSchoolId === val) {
                this.studentRankClassList =
                  item2.examAchievementSchoolClassConditionDTOS;
              }
            }
          );
        });
        this.studentChartForm.studentName = "";
        this.studentChartForm.sysOrgSchoolClassId = null;
      }
      this.fetchStudentRankData()
    },
    // 学校表格区域下拉选择事件
    handleSchoolFormAreaSelectChange(val) {
      if (!val) {
        this.schoolFormSchoolList = [];
      }
      // 选择成功后，就填充学校下拉表单
      this.tempStudentRankCondition.forEach((item) => {
        if (item.sysOrgAreaId === val) {
          this.schoolFormSchoolList =
            item.examAchievementSchoolConditionDTOList;
        }
      });
      this.schoolChartForm.sysOrgSchoolId = null;
      this.fetchSchoolRankData()
    },
    // 切换分页组件事件
    handleSizeChangeStudentChartMsgPage(val) {
      this.studentChartMsgPage.pageSize = val;
      this.studentChartMsgPage.pageIndex = 1;
      this.fetchStudentRankData();
    },
    handleCurrentChangeStudentChartMsgPage(val) {
      this.studentChartMsgPage.pageIndex = val;
      this.fetchStudentRankData();
    },
    // 学校排行榜表格分页事件
    handleCurrentChangeSchoolChartMsgPage(val) {
      this.schoolChartMsgPage.pageIndex = val;
      this.fetchSchoolRankData()
    },
    handleSizeChangeSchoolChartMsgPage(val) {
      this.schoolChartMsgPage.pageSize = val;
      this.schoolChartMsgPage.pageIndex = 1;
      this.fetchSchoolRankData()
    },
    // 条件查询学校排行榜
    handleFindOutSchoolRankDataBtn() {
      // 获取学校排行榜数据
      this.schoolChartMsgPage.pageIndex = 1;
      this.schoolChartMsgPage.pageSize = 10;
      this.fetchSchoolRankData()
    },
    // querySchoolDataFunc() {
    //   if (
    //     !this.schoolChartForm.sysOrgAreaId &&
    //     !this.schoolChartForm.sysOrgSchoolId
    //   ) {
    //     this.fetchSchoolRankData();
    //   } else if (
    //     this.schoolChartForm.sysOrgAreaId &&
    //     !this.schoolChartForm.sysOrgSchoolId
    //   ) {
    //     let areaName = "";
    //     this.schoolFormAreaList.forEach((item) => {
    //       if (item.sysOrgAreaId === this.schoolChartForm.sysOrgAreaId) {
    //         areaName = item.sysOrgAreaName;
    //       }
    //     });
    //     let tempArr = [];
    //     this.schoolChartTableData = [];
    //     this.testSchoolChartTableData.forEach((item) => {
    //       if (item.sysOrgAreaName === areaName) {
    //         tempArr.push(item);
    //       }
    //     });
    //     this.schoolChartTableData = tempArr.slice(0, 10);
    //     this.schoolChartMsgPage.pageTotal = tempArr.length;
    //   } else {
    //     let areaName = "",
    //       schoolName = "";
    //     this.schoolFormAreaList.forEach((item) => {
    //       if (item.sysOrgAreaId === this.schoolChartForm.sysOrgAreaId) {
    //         areaName = item.sysOrgAreaName;
    //       }
    //     });
    //     this.schoolFormSchoolList.forEach((item) => {
    //       if (item.sysOrgSchoolId === this.schoolChartForm.sysOrgSchoolId) {
    //         schoolName = item.sysOrgSchoolName;
    //       }
    //     });
    //     let tempArr = [];
    //     this.schoolChartTableData = [];
    //     this.testSchoolChartTableData.forEach((item) => {
    //       if (
    //         item.sysOrgAreaName === areaName &&
    //         item.sysOrgSchoolName === schoolName
    //       ) {
    //         tempArr.push(item);
    //       }
    //     });
    //     this.schoolChartTableData = tempArr.slice(0, 10);
    //     this.schoolChartMsgPage.pageTotal = tempArr.length;
    //   }
    // },
    handleResetSchoolData() {
      this.schoolChartForm = {
        sysOrgAreaId: null,
        sysOrgSchoolId: null,
        subjectType: +this.$route.query.subject,
      };
      this.schoolFormSchoolList = [];
      this.fetchSchoolRankData();
    },
    // 条件查询学生排行榜
    handleFindOutStudentRankBtn() {
      this.studentChartTableData = [];
      this.studentChartMsgPage.pageIndex = 1;
      this.studentChartMsgPage.pageSize = 10;
      this.fetchStudentRankData();
    },
    handleResetStudentData() {
      this.studentChartForm = {
        studentName: "",
        sysOrgSchoolClassId: null,
        subjectType: "",
        sysOrgSchoolId: null,
        sysOrgAreaId: null,
        subjectType: +this.$route.query.subject,
      };
      this.studentRankSchoolList = [];
      this.studentRankClassList = [];
      this.studentChartForm.studentName = "";
      this.fetchStudentRankData();
    },
    // 改变底部蓝色线条激活样式
    changeBottomLineStyle(val) {
      const bottomLine = document.querySelector(".bottomLine");
      if (val === 1) {
        bottomLine.style.left = "104px";
      } else {
        bottomLine.style.left = "0px";
      }
    },
    // 改变学生排序
    handleSortChange(val) {
      if (this.scoreRankType === 0) {
        if (val.order === "descending") {
          this.sortType = 1;
          this.fetchStudentRankData();
        } else if (val.order === "ascending") {
          this.sortType = 2;
          this.fetchStudentRankData();
        }
      } else if (this.scoreRankType === 1) {
        if (val.order === "descending") {
          this.sortType = 1;
          // this.schoolChartMsgPage.pageIndex = 1;
          this.fetchSchoolRankData();
        } else if (val.order === "ascending") {
          this.sortType = 2;
          // this.schoolChartMsgPage.pageIndex = 1;
          this.fetchSchoolRankData();
        }
      }
    },
  },
  filters: {
    subjectTypeFilters(key) {
      switch (key) {
        case 1:
          return `物理`;
        case 2:
          return `生物`;
        case 3:
          return `化学`;
        default:
          return ``;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scoreChartDetailContainer {
  padding: 0 20px;
}
.tabsTitle {
  position: relative;
  margin-bottom: 10px;
  &::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    top: 30px;
    background: #ccc;
  }
  .active {
    color: rgb(64, 158, 255);
  }
}
.studentChartFormContainer {
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  right: 70px;
  top: -18px;
  z-index: 9;
}
.studentRank {
  width: 25px;
  height: 25px;
  background: rgb(24, 144, 255);
  line-height: 25px;
  border-radius: 50%;
  color: #fff;
}
::v-deep .rankContainer {
  .cell {
    text-align: -webkit-center;
  }
}
</style>
